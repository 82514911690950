import * as React from 'react'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
// import Typography from '@mui/material/Typography'
import AppBar from '../components/AppBar'
import Toolbar from '../components/Toolbar'
import i18n from 'i18next'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import HomeIcon from '@mui/icons-material/Home'

const LANGUAGES = [
  {
    code: 'ja',
    name: '日本語'
  },
  {
    code: 'en',
    name: 'English'
  }
]

var LANGUAGE_CODE = window.navigator.language
// var LANGUAGE_CODE = "aaaaa"

function AppAppBar() {
  if (LANGUAGE_CODE !== 'ja') {
    LANGUAGE_CODE = 'en'
    i18n.changeLanguage(LANGUAGE_CODE)
  }

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              position: 'fixed',
              // display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            HOME
          </Typography> */}
          <IconButton aria-label="home" href="/" size="large">
            <HomeIcon fontSize="inherit" />
          </IconButton>
          <Box sx={{ flex: 1 }} />
          <Hidden mdDown>
            <Link variant="h6" underline="none" color="inherit" sx={{ fontSize: 24 }}>
              {'BQ JOIN CHECKER'}
            </Link>
          </Hidden>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Link
              color="inherit"
              variant="h6"
              underline="none"
              href="/premium-themes/onepirate/sign-in/"
              sx={rightLink}
            >
              {'Sign In'}
            </Link>
            <Link
              variant="h6"
              underline="none"
              href="/premium-themes/onepirate/sign-up/"
              sx={{ ...rightLink, color: 'secondary.main' }}
            >
              {'Sign Up'}
            </Link> */}
            <RadioGroup
              row
              aria-labelledby="language"
              name="language"
              defaultValue={LANGUAGE_CODE}
              onChange={(event) => i18n.changeLanguage(event.target.value)}
            >
              {LANGUAGES.map((language) => (
                <FormControlLabel
                  key={language.code}
                  value={language.code}
                  control={<Radio color="default" size="small" />}
                  label={
                    <span style={{ color: 'lightgrey' }}>
                      {window.innerWidth <= 680 ? language.code : language.name}
                    </span>
                  }
                />
              ))}
            </RadioGroup>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  )
}

export default AppAppBar
