import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '../components/Typography'
import Paper from '@mui/material/Paper'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'

import screenShot1 from '../../image/screenshot1.png'
import screenShot2 from '../../image/screenshot2.png'
import screenShot3 from '../../image/screenshot3.png'
import screenShot4 from '../../image/screenshot4.png'

export default function ProductHowToUse() {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = React.useState(0)

  const steps = [
    {
      label: t('howto.install.label'),
      description: t('howto.install.description'),
      image: screenShot1
    },
    {
      label: t('howto.fix.label'),
      description: t('howto.fix.description'),
      image: screenShot2
    },
    {
      label: t('howto.use.label'),
      description: t('howto.use.description'),
      image: screenShot3
    },
    {
      label: t('howto.execute.label'),
      description: t('howto.execute.description'),
      image: screenShot4
    }
  ]

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Container component="section" sx={{ mt: 10, mb: 10 }}>
      <Typography variant="h4" marked="center" align="center" component="h2">
        How to use
      </Typography>
      <Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <img width="60%" src={step.image} alt="step" loading="lazy" />
                <Typography>{step.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                      {index === steps.length - 1 ? 'Finish' : 'Continue'}
                    </Button>
                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
        <Button
          color="info"
          href="https://chrome.google.com/webstore/detail/bq-join-checker/gnnenfnpfdlonkonljannnmiengokimj"
          fullWidth
          sx={{ mt: 10, py: 2 }}
          variant="contained"
        >
          {t('howto.download')}
        </Button>
      </Box>
    </Container>
  )
}
