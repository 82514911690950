import React, { useEffect } from 'react'
import mermaid from 'mermaid'

export interface MermaidProps {
  text: string
}

export const Mermaid: React.FC<MermaidProps> = ({ text }) => {
  const ref = React.useRef(document.createElement('div'))

  useEffect(() => {
    mermaid.mermaidAPI.initialize({
      startOnLoad: true,
      securityLevel: 'loose',
      theme: 'forest',
      logLevel: 5
    })
  })

  useEffect(() => {
    if (ref.current && text !== '') {
      mermaid.mermaidAPI.render('preview', text, (result: any) => {
        ref.current.innerHTML = result
      })
      //   mermaid.mermaidAPI.render('preview', text, undefined)
    }
  }, [text])

  return <div key="preview" ref={ref} />
}
