import * as React from 'react'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Container from '@mui/material/Container'
import Typography from '../components/Typography'
import { HealthAndSafety, Update, MoneyOff } from '@mui/icons-material'
import productCurvyLines from '../../image/productCurvyLines.png'
import { useTranslation } from 'react-i18next'

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5
}

function ProductValues() {
  const { t } = useTranslation()
  return (
    <Box component="section" sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}>
      <Container sx={{ mt: 20, mb: 20, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src={productCurvyLines}
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/* <Box
                component="img"
                src="/static/themes/onepirate/productValues1.svg"
                alt="suitcase"
                sx={{ height: 55 }}
              /> */}
              <Avatar sx={{ width: 80, height: 80 }}>
                <HealthAndSafety sx={{ width: 40, height: 40 }} />
              </Avatar>
              <Typography variant="h6" sx={{ my: 5 }}>
                SAFETY
              </Typography>
              <Typography variant="h5">{t('values.safety')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/* <Box
                component="img"
                src="/static/themes/onepirate/productValues2.svg"
                alt="graph"
                sx={{ height: 55 }}
              /> */}
              <Avatar sx={{ width: 80, height: 80 }}>
                <Update sx={{ width: 40, height: 40 }} />
              </Avatar>
              <Typography variant="h6" sx={{ my: 5 }}>
                EASY
              </Typography>
              <Typography variant="h5">{t('values.easy')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/* <Box
                component="img"
                src="/static/themes/onepirate/productValues3.svg"
                alt="clock"
                sx={{ height: 55 }}
              /> */}
              <Avatar sx={{ width: 80, height: 80 }}>
                <MoneyOff sx={{ width: 40, height: 40 }} />
              </Avatar>
              <Typography variant="h6" sx={{ my: 5 }}>
                FREE
              </Typography>
              <Typography variant="h5">{t('values.free')}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ProductValues
