import * as React from 'react'
import ProductHowToUse from './modules/views/ProductHowToUse'
import ProductFeatures from './modules/views/ProductFeatures'
import AppFooter from './modules/views/AppFooter'
import ProductHero from './modules/views/ProductHero'
import ProductValues from './modules/views/ProductValues'
// import ProductTrial from './modules/views/ProductTrial'
import ProductDiagram from './modules/views/ProductDiagram'
import ProductForm from './modules/views/ProductForm'
import AppAppBar from './modules/views/AppAppBar'
import withRoot from './modules/withRoot'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// https://github.com/alinemorelli/react-gtm
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-NFG3GLQ'
}
TagManager.initialize(tagManagerArgs)

function Index() {
  return (
    <div className="app">
      <Router basename={process.env.PUBLIC_URL}>
        <React.Fragment>
          <AppAppBar />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <ProductHero />
                  <ProductValues />
                  <ProductHowToUse />
                  {/* <ProductTrial /> */}
                  <ProductFeatures />
                </>
              }
            />
            <Route
              path="/viewer"
              element={
                <>
                  <ProductDiagram />
                </>
              }
            />
            <Route
              path="/viewer.html"
              element={
                <>
                  <ProductDiagram />
                </>
              }
            />
            <Route
              path="*"
              element={
                <>
                  <ProductHero />
                  <ProductValues />
                  <ProductHowToUse />
                  {/* <ProductTrial /> */}
                  {/* <ProductCTA /> */}
                  <ProductFeatures />
                </>
              }
            />
          </Routes>
          <ProductForm />
          <AppFooter />
        </React.Fragment>
      </Router>
    </div>
  )
}

export default withRoot(Index)
