import * as React from 'react'
import Typography from '../components/Typography'
import ProductHeroLayout from './ProductHeroLayout'
import bgImage from '../../image/check.jpg'
import { useTranslation } from 'react-i18next'

// const backgroundImage = "./lp/src/image/check.jpg";
// 'https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400';

export default function ProductHero() {
  const { t } = useTranslation()
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${bgImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center'
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={bgImage} alt="increase priority" />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Check Table Join More Easily
      </Typography>
      <Typography color="inherit" align="center" variant="h5" sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}>
        {/* SQLにおけるレコード数確認とjoin構成図の作成が行なえます */}
        {t('header.message')}
      </Typography>
      {/* <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="/premium-themes/onepirate/sign-up/"
        sx={{ minWidth: 200 }}
      >
        Register
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Discover the experience
      </Typography> */}
    </ProductHeroLayout>
  )
}
