import * as React from 'react'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'
import Typography from '../components/Typography'
import Fab from '@mui/material/Fab'
import { useTranslation } from 'react-i18next'

function Copyright() {
  return (
    <React.Fragment>
      {'Copyright © '}
      <Link color="inherit" href="https://sink-capital.com/">
        SinkCapital
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  )
}

// const iconStyle = {
//   width: 48,
//   height: 48,
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   backgroundColor: 'warning.main',
//   mr: 1,
//   '&:hover': {
//     bgcolor: 'warning.dark'
//   }
// }

export default function AppFooter() {
  const { t } = useTranslation()
  return (
    <Typography align="center" component="footer" sx={{ display: 'flex', bgcolor: 'secondary.light' }}>
      <Container sx={{ my: 8, display: 'flex' }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container direction="column" justifyContent="flex-end" spacing={2} sx={{ height: 20 }}>
              {/* <Grid item sx={{ display: 'flex' }}>
                <Box component="a" href="https://mui.com/" sx={iconStyle}>
                  <img src="/static/themes/onepirate/appFooterFacebook.png" alt="Facebook" />
                </Box>
                <Box component="a" href="https://twitter.com/MUI_hq" sx={iconStyle}>
                  <img src="/static/themes/onepirate/appFooterTwitter.png" alt="Twitter" />
                </Box>
              </Grid> */}
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Fab
          color="info"
          href="https://www.sink-capital.com/contact/form/"
          sx={{ position: 'fixed', bottom: '20px', right: '20px', pr: 5, pl: 5 }}
          variant="extended"
        >
          {t('message.consultation')}
        </Fab>
      </Container>
    </Typography>
  )
}
