import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '../components/Typography'
import { Mermaid, MermaidProps } from '../components/Mermaid'
import Button from '../components/Button'
import html2canvas from 'html2canvas'
// import productCurvyLines from '../../image/productCurvyLines.png'

import './ProductDiagram.modeule.css'

function download() {
  const title = `mermaid_${getDateString()}.png`
  const target = document.querySelector('#mermaiddiagram div') as HTMLElement
  target.style.width = '3096px'
  if (target) {
    html2canvas(target, {
      scale: 3
    }).then((canvas) => {
      const downloadEle = document.createElement('a')
      downloadEle.href = canvas.toDataURL('image/png')
      downloadEle.download = title
      downloadEle.click()
    })
  }
}

function getDateString() {
  const dt = new Date()
  const y = dt.getFullYear()
  const m = ('00' + (dt.getMonth() + 1)).slice(-2)
  const d = ('00' + dt.getDate()).slice(-2)
  const h = ('00' + dt.getHours()).slice(-2)
  const ms = ('00' + dt.getMinutes()).slice(-2)
  const s = ('00' + dt.getSeconds()).slice(-2)
  return `${y}-${m}-${d} ${h}.${ms}.${s}`
}

function ProductDiagram() {
  const text = localStorage.getItem('DIAGRAM_MERMAID')

  // const [mermaidText, setMermaidText] = useState(text)

  const mermaidProps: MermaidProps = {
    text: ''
  }
  if (text) {
    mermaidProps.text = text
  }

  return (
    <Box component="section" sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden' }}>
      <Container
        id="mermaiddiagram"
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {/* <Box
          component="img"
          src={productCurvyLines}
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7
          }}
        /> */}
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 5 }}>
          DIAGRAM
        </Typography>
        <Mermaid {...mermaidProps} />
        <Button onClick={download} variant="contained" sx={{ mt: 5 }}>
          DOWNLOAD
        </Button>
      </Container>
    </Box>
  )
}

export default ProductDiagram
