import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Typography from '../components/Typography'
import FormButton from '../form/FormButton'
import withRoot from '../withRoot'

function PrtoductForm() {
  const sent = false
  const submitting = false

  return (
    <Box component="section" sx={{ display: 'flex', bgcolor: '#212121', overflow: 'hidden' }}>
      <Container component="section" sx={{ pt: 10, pb: 10 }}>
        <React.Fragment>
          <Typography variant="h3" color="white" gutterBottom marked="center" align="center">
            Contact US
          </Typography>
        </React.Fragment>
        <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfnXBumIaaNRv4HYxiNHHctJVz5qm8zmJBp1TBm2w6GNKApAA/formResponse">
          <Box sx={{ mt: 6, color: 'white' }}>
            <TextField
              disabled={submitting || sent}
              fullWidth
              label="Name"
              margin="normal"
              name="entry.892799679"
              style={{ backgroundColor: 'white' }}
              variant="filled"
              required
            />
            <TextField
              autoComplete="email"
              disabled={submitting || sent}
              fullWidth
              label="Email"
              margin="normal"
              name="entry.1833062606"
              style={{ backgroundColor: 'white' }}
              variant="filled"
              required
            />
            <TextField
              fullWidth
              disabled={submitting || sent}
              required
              name="entry.1233926935"
              label="Message"
              margin="normal"
              style={{ backgroundColor: 'white' }}
              variant="filled"
              multiline
              rows={4}
            />
            <FormButton sx={{ mt: 3, mb: 2 }} disabled={submitting || sent} color="secondary" fullWidth>
              {submitting || sent ? 'In progress…' : 'Submit'}
            </FormButton>
          </Box>
        </form>
      </Container>
    </Box>
  )
}

export default withRoot(PrtoductForm)
