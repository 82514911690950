import * as React from 'react'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import Container from '@mui/material/Container'
import Typography from '../components/Typography'
import Grid from '@mui/material/Grid'
import Rating from '@mui/material/Rating'

import bgImage from '../../image/check2.jpg'

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: 'white',
  px: 5
}

function ProductFeatures() {
  return (
    <Container
      component="section"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 9,
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minWidth: '100vw'
      }}
    >
      <Container sx={{ mt: 10, mb: 10, display: 'flex', position: 'relative' }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/* <Typography variant="h2" sx={{ my: 5, color: 'white' }}>
                1
              </Typography> */}
              <Rating name="read-only" value={5} sx={{ mt: 7, mb: 7.2 }} readOnly />
              <Typography variant="h5">REVIEWS</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Typography variant="h2" sx={{ my: 5, color: 'white' }}>
                0.6.1
              </Typography>
              <Typography variant="h5">VERSION</Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <Box sx={item}>
              <Typography variant="h2" sx={{ my: 5, color: 'white' }}>
                5.0
              </Typography>
              <Typography variant="h5">STARS</Typography>
            </Box>
          </Grid> */}
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Typography variant="h2" sx={{ my: 5, color: 'white' }}>
                37
              </Typography>
              <Typography variant="h5">DOWNLOADS</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}

export default ProductFeatures
